// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.progress {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 20px auto;
}

.progress__text {
  font-size: 18px;
  margin-bottom: 10px;
  color: #333;
}

.progress__bar {
  width: 80%;
  height: 20px;
  background-color: rgb(233, 231, 222);
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.progress__bar-fill {
  height: 100%;
  background-color: rgb(122,148,90);
  transition: width 0.2s ease;
}
`, "",{"version":3,"sources":["webpack://./src/components/Progress.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,WAAW;EACX,iBAAiB;AACnB;;AAEA;EACE,eAAe;EACf,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,UAAU;EACV,YAAY;EACZ,oCAAoC;EACpC,mBAAmB;EACnB,gBAAgB;EAChB,wCAAwC;AAC1C;;AAEA;EACE,YAAY;EACZ,iCAAiC;EACjC,2BAA2B;AAC7B","sourcesContent":[".progress {\r\n  display: flex;\r\n  flex-direction: column;\r\n  align-items: center;\r\n  width: 100%;\r\n  margin: 20px auto;\r\n}\r\n\r\n.progress__text {\r\n  font-size: 18px;\r\n  margin-bottom: 10px;\r\n  color: #333;\r\n}\r\n\r\n.progress__bar {\r\n  width: 80%;\r\n  height: 20px;\r\n  background-color: rgb(233, 231, 222);\r\n  border-radius: 10px;\r\n  overflow: hidden;\r\n  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\r\n}\r\n\r\n.progress__bar-fill {\r\n  height: 100%;\r\n  background-color: rgb(122,148,90);\r\n  transition: width 0.2s ease;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
