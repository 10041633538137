import logo from '../images/logo_black.png';
import vk from '../images/VK.png'

function Footer() {
  return (
    <div className="footer">
      <div className='footer__link'>
        <a href="https://vk.com/art_bazar" target="_blank" rel="noopener noreferrer">
        <img className="footer__vk" src={vk} alt="Кнопка вконтакте" />
      </a>
      </div>
      <div className='footer__info'>
        <p className='footer__text'>
          Сделайте заказ в лучшей доставке города:<br />
          <a className="footer__phone" href="tel:+79226002900">&#x1F4DE;
            <span className='footer__text'>+792-2600-2900</span>
          </a>
        </p>
        <p className='footer__text'>
          Вс - Чт с 10:00 до 22:30<br />
          Пт - Сб с 10:00 до 23:30
        </p>
        <p className='footer__text'>
          Скучно дома? Приходите в гости.<br />
          <a className="footer__phone" href="tel:+73435760120">&#x1F4DE;
            <span className='footer__text'>+734357-60-120</span>
          </a>
          У нас уютно и работает детская комната!<br />
          г. Кировград, ул. Свердлова, 53Д
        </p>
      </div>
      <div>
        <a href="/"><img className="footer-logo" src={logo} alt="Логотип" /></a>
      </div>
    </div>
  )
}

export default Footer;
