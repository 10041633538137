// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.popup-consent {
  max-width: 80vw;
  max-height: 80vh;
  background-color: rgb(233, 231, 222);
  padding: 30px 20px;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  gap: 30px;
  position: relative;
  overflow-y: auto;
}

.popup-consent__h2 {
  margin-top: 0;
  margin-bottom: 0;
  text-align: center;
}

.popup-consent__button {
  padding: 10px;
  margin-bottom: 10px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/components/PopupConsent.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,gBAAgB;EAChB,oCAAoC;EACpC,kBAAkB;EAClB,mBAAmB;EACnB,yCAAyC;EACzC,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,eAAe;EACf,YAAY;EACZ,eAAe;AACjB","sourcesContent":[".popup-consent {\r\n  max-width: 80vw;\r\n  max-height: 80vh;\r\n  background-color: rgb(233, 231, 222);\r\n  padding: 30px 20px;\r\n  border-radius: 10px;\r\n  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);\r\n  display: flex;\r\n  flex-direction: column;\r\n  gap: 30px;\r\n  position: relative;\r\n  overflow-y: auto;\r\n}\r\n\r\n.popup-consent__h2 {\r\n  margin-top: 0;\r\n  margin-bottom: 0;\r\n  text-align: center;\r\n}\r\n\r\n.popup-consent__button {\r\n  padding: 10px;\r\n  margin-bottom: 10px;\r\n  font-size: 16px;\r\n  border: none;\r\n  cursor: pointer;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
