import React, { useState, useContext, useEffect, useRef } from 'react';
import { CartContext } from '../contexts/cardContext';
import defImage from '../images/defImage_dark.png';
import Navigation from './Navigation';

function Products({ groups, products }) {
  const [showPopup, setShowPopup] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState({});
  const [selectedModifiers, setSelectedModifiers] = useState({});
  const [progress, setProgress] = useState(0);
  const { addToCart, cart, decreaseItemAmount, removeItem } = useContext(CartContext);
  const hiddenProducts = ['Коробка для пиццы 30 см', 'Коробка для пиццы 40 см'];
  const { updatePizzaBoxes } = useContext(CartContext);
  const [currentSection, setCurrentSection] = useState(null);
  const sectionsRef = useRef([]);
  const navigationRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const sectionOffsets = sectionsRef.current.map(section => ({
        id: section?.id,
        offsetTop: section?.offsetTop || 0,
      }));
      const scrollPosition = window.scrollY;
      const activeSection = sectionOffsets.find((section, index) => {
        const nextSection = sectionOffsets[index + 1];
        return nextSection
          ? scrollPosition >= section.offsetTop && scrollPosition < nextSection.offsetTop
          : scrollPosition >= section.offsetTop;
      });
      if (activeSection?.id !== currentSection) {
        setCurrentSection(activeSection?.id || null);
        const activeNavElement = navigationRef.current?.querySelector(`[data-id="${activeSection?.id}"]`);
        if (activeNavElement) {
          activeNavElement.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
        }
      }
    };
  
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [currentSection]);

  const handleSectionChange = (groupId) => {
    const section = document.getElementById(groupId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    if (groups && products) {
      const pizzaBoxes = findPizzaBoxes(products);
      updatePizzaBoxes(pizzaBoxes);
    }
  }, []);

  useEffect(() => {
    if (!groups || !products) {
      const interval = setInterval(() => {
        setProgress((prev) => (prev < 100 ? prev + 0.5 : 100));
      }, 100);
      return () => clearInterval(interval);
    }
  }, [groups, products]);
  if (!groups || !products) {
    return (
      <div className="progress">
        <p className="progress__text">Меню скоро загрузится</p>
        <div className="progress__bar">
          <div
            className="progress__bar-fill"
            style={{ width: `${progress}%` }}
          ></div>
        </div>
      </div>
    );
  }

  const findPizzaBoxes = (products) => {
    const pizzaBox30 = products.find(product => product.name === 'Коробка для пиццы 30 см');
    const pizzaBox40 = products.find(product => product.name === 'Коробка для пиццы 40 см');
    return { pizzaBox30, pizzaBox40 };
  };

  const generateItemKey = (id, modifiers = []) => {
    const sortedModifiers = modifiers.map((mod) => mod.id).sort();
    return `${id}-${sortedModifiers.join('-')}`;
  };

  const openPopup = (product) => {
    setSelectedProduct(product);
    setSelectedModifiers({});
    setShowPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  const handleCategoryClick = (groupId, parentId) => {
    setSelectedCategory((prev) => ({
      ...prev,
      [parentId]: groupId,
    }));
  };

  const findModifierById = (modifierId) => {
    const modifier = products.find(
      (product) => product.id === modifierId && product.type === 'Modifier'
    );
    if (modifier) {
      const modifierPrice = modifier.price || 0;
      return { 
        name: modifier.name, 
        groupId: modifier.groupId,
        price: modifierPrice 
      };
    } else {
      return null;
    }
  };

  const handleModifierChange = (modifierId, price, checked, groupId) => {
    setSelectedModifiers((prev) => {
      const findGroupId = (groups) => {
        const groupSouce = groups.find(group => group.name === 'Соус на выбор');
        return groupSouce ? groupSouce.id : null;
      };
      const groupSouceId = findGroupId(groups);
      if (groupId === groupSouceId && checked) {
        return {
          [modifierId]: { price, groupId },
        };
      }
      return {
        ...prev,
        [modifierId]: checked ? { price, groupId } : null,
      };
    });
  };

  const handleAddToCart = () => {
    const selectedModifiersList = Object.entries(selectedModifiers)
      .filter(([, value]) => value !== null)
      .map(([modifierId, { price, groupId }]) => {
        const modifier = findModifierById(modifierId);
        return {
          id: modifierId,
          name: modifier?.name || "Неизвестный модификатор",
          price,
          amount: 1,
          productGroupId: groupId,
        };
      });
    const itemKey = generateItemKey(selectedProduct.id, selectedModifiersList);
    addToCart({
      key: itemKey,
      ...selectedProduct,
      totalPrice: getTotalPrice(),
      modifiers: selectedModifiersList,
      amount: 1,
    });
    setSelectedModifiers({});
  };

  const getTotalPrice = () => {
    const modifiersTotal = Object.values(selectedModifiers)
      .filter((modifier) => modifier !== null)
      .reduce((sum, { price }) => sum + price, 0);
    return selectedProduct.price + modifiersTotal;
  };

  const getCartItemAmount = (product) => {
    const uniqueKey = generateItemKey(product.id, product.modifiers || []);
    const cartItem = cart.find((item) => item.key === uniqueKey);
    return cartItem ? cartItem.amount : 0;
  };

  const getCartItemAmountWithModifiers = (product, hasModifiers) => {
    return cart
      .filter((item) => item.productId === product.id && !!item.modifiers.length === hasModifiers)
      .reduce((sum, item) => sum + item.amount, 0);
  };

  const getCartItemTotalPriceWithModifiers = (product) => {
    return cart
      .filter((item) => item.productId === product.id && item.modifiers.length > 0)
      .reduce((sum, item) => {
        const modifiersTotal = item.modifiers.reduce((modSum, mod) => modSum + mod.price, 0);
        return sum + (product.price + modifiersTotal) * item.amount;
      }, 0);
  };
  if (!groups || !products) {
    return <div>Загружаем меню, чуть-чуть нужно подождать...</div>;
  }

  return (
    <>
      <Navigation
        ref={navigationRef}
        groups={groups}
        activeGroup={currentSection}
        onSectionChange={handleSectionChange}
      />
      <div className='menu'>
        {groups.filter(group => group.parentGroup === null && !group.isGroupModifier).map((group, index) => {
          const subGroups = groups.filter(subGroup => subGroup.parentGroup === group.id);
          if (group.name.toLowerCase().includes("доставка")) {
            return null;
          }  
          return (
            <div
              key={group.id}
              id={group.id}
              ref={(el) => (sectionsRef.current[index] = el)}
              className="menu__box"
            >
              {group.name && !group.name.toLowerCase().includes("доставка") && (
                <h2 className='menu__title'>{group.name}</h2>
              )}
              {subGroups.length > 0 && !subGroups.some(subGroup => subGroup.name.toLowerCase().includes("невьянск")) && (
                <div className="menu__categories">
                  <button
                    onClick={() => handleCategoryClick(null, group.id)}
                    className="menu__category-button"
                  >
                    Все
                  </button>
                  {subGroups.map(subGroup => (
                    <button
                      key={subGroup.id}
                      onClick={() => handleCategoryClick(subGroup.id, group.id)}
                      className="menu__category-button"
                    >
                      {subGroup.name}
                    </button>
                  ))}
                </div>
              )}
              <div className="products">
                {products.filter(product => {
                  if (!product.isIncludedInMenu || product.isDeleted) {
                    return false;
                  }
                  if (product.paymentSubject === "УСЛУГА") {
                    return false;
                  }
                  if (hiddenProducts.includes(product.name)) {
                    return false;
                  }
                  const selectedSubCategory = selectedCategory[group.id];
                    if (selectedSubCategory) {
                      return product.parentGroup === selectedSubCategory;
                    }
                  return product.parentGroup === group.id ||
                    groups.some(subGroup => subGroup.parentGroup === group.id &&
                                            subGroup.id === product.parentGroup);
                }).map(product => {
                  const amountInCart = getCartItemAmount(product);
                    return (
                      <div key={product.name} className="product">
                        <img
                          src={product.image || defImage}
                          alt={product.name}
                          className="product__image"
                          onClick={() => openPopup(product)}
                        />
                        <h3 className="product__name">{product.name}</h3>
                        <div className="product__buttons">
                          {amountInCart > 0 && (
                            <button
                              className="product__button-minus"
                              onClick={() => decreaseItemAmount(generateItemKey(product.id, product.modifiers || []))}
                            >
                            </button>
                          )}
                          <button
                            className="product__button-add"
                            onClick={() => addToCart(product, products)}
                          >
                            {product.price} р {amountInCart > 0 && `x ${amountInCart}`}
                          </button>
                      {amountInCart > 0 && (
                        <button
                          className="product__button-remove"
                          onClick={() => removeItem(generateItemKey(product.id, product.modifiers || []))}
                        >
                        </button>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
      {showPopup && selectedProduct && (
          <div className="popup__overlay" onClick={closePopup}>
            <div className='product-popup' onClick={(e) => e.stopPropagation()}>
            <button className="product-popup__close-button" onClick={closePopup}></button>
            <img className="product-popup__image" src={selectedProduct.image || defImage} alt={selectedProduct.name} />
            <div className="product-popup__description">
              <div className="">
                <h2 className="product-popup__heading">{selectedProduct.name}</h2>
                <p className="product-popup__about">{selectedProduct.description}</p>
                {/* <p className="product-popup__ingredients">Состав: </p> */}
                {selectedProduct.groupModifiers && selectedProduct.groupModifiers.length > 0 ? (
                  <div className=''>
                    <h3>Добавить ингредиент:</h3>
                    <ul className='product-popup__modificator'>
                      {selectedProduct.groupModifiers.map(groupModifier => (
                        <li className='product-popup__modificator-li' key={groupModifier.id}>
                          {groupModifier.childModifiers && groupModifier.childModifiers.length > 0 ? (
                            groupModifier.childModifiers.map(childModifier => {
                              const modifier = findModifierById(childModifier.id);
                              const isChecked = !!selectedModifiers[childModifier.id];
                              return (
                                <div key={childModifier.id}>
                                  <label
                                    onClick={() => handleModifierChange(
                                      childModifier.id,
                                      modifier.price,
                                      !isChecked,
                                      modifier.groupId
                                    )}
                                    className={isChecked ? 'product-popup__modifier-checked' : 'product-popup__modifier-unchecked'}
                                  >
                                    {modifier ? modifier.name : "Модификатор не найден"}
                                  </label>
                                </div>
                              );
                            })
                          ) : (
                            <p>Нет модификаторов</p>
                          )}
                        </li>
                      ))}
                    </ul>
                  </div>
                ) : (
                  <p></p>
                )}
              </div>
              <div>
                <h3>Цена: {getTotalPrice()} р</h3>
                {selectedProduct.groupModifiers && selectedProduct.groupModifiers.length > 0 ? (
                  <div>
                    <p>Добавлено: {getCartItemAmountWithModifiers(selectedProduct, false)} шт. - {getCartItemAmountWithModifiers(selectedProduct, false) * selectedProduct.price} руб.</p>
                    <p>С дополнительными ингридиентами: {getCartItemAmountWithModifiers(selectedProduct, true)} шт. - {getCartItemTotalPriceWithModifiers(selectedProduct)} руб.</p>
                  </div>
                ) : (
                  <p>В корзине: {getCartItemAmount(selectedProduct)} шт. - общая цена: {getCartItemAmount(selectedProduct) * getTotalPrice()} руб.</p>
                )}
              </div>
              <div className="product__buttons">
                {getCartItemAmount(selectedProduct) > 0 && (
                  <button
                    className="product__button-minus"
                    onClick={() => decreaseItemAmount(generateItemKey(selectedProduct.id, Object.keys(selectedModifiers)))}
                  >
                  </button>
                )}
                <button
                  className="product__button-add"
                  onClick={handleAddToCart}
                >
                  Добавить
                </button>
                {getCartItemAmount(selectedProduct) > 0 && (
                  <button
                    className="product__button-remove"
                    onClick={() => {
                      removeItem(`${selectedProduct.id}-${Object.keys(selectedModifiers).join('-')}`);
                      closePopup();
                    }}
                  >
                  </button>
                )}
              </div>
            </div>
            </div>
          </div>
      )}
    </div>
    </>
  );
}

export default Products;
