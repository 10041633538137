import React, { createContext, useState, useEffect } from 'react';

export const CartContext = createContext();
export const CartProvider = ({ children, menu }) => {
  const [pizzaBoxes, setPizzaBoxes] = useState({});
  const updatePizzaBoxes = (products) => {
    console.log('Обновляем коробки для пиццы:', products);
    setPizzaBoxes(products);
  };

  const loadCartFromLocalStorage = () => {
    const savedCart = localStorage.getItem('cart');
    return savedCart ? JSON.parse(savedCart) : [];
  };
  const [cart, setCart] = useState(loadCartFromLocalStorage);

  useEffect(() => {
    localStorage.setItem('cart', JSON.stringify(cart));
    updatePizzaBoxesCount(cart);
  }, [cart]);

  const updatePizzaBoxesCount = (updatedCart) => {
    let pizzaBox30 = 0;
    let pizzaBox40 = 0;

    updatedCart.forEach((item) => {
      if (item.name.toLowerCase().includes('пицца') && item.name.toLowerCase().includes('30 см')) {
        pizzaBox30 += item.amount;
      }
      if (item.name.toLowerCase().includes('пицца') && item.name.toLowerCase().includes('40 см')) {
        pizzaBox40 += item.amount;
      }
      if (item.name.toLowerCase().includes('пирог') || item.name.toLowerCase().includes('хачапури по-мегрельски')) {
        pizzaBox30 += item.amount;
      }
    });

    const updatedPizzaBoxes = {};

    if (pizzaBox30 > 0) {
      updatedPizzaBoxes.pizzaBox30 = {
        id: '76708225-e1f3-4e69-9e82-fe3f9eda2129',
        name: 'Коробка для пиццы 30 см',
        price: 30,
        amount: pizzaBox30,
        productGroupId: '637a6aea-1087-43b6-a211-d815be229955',
      };
    }

    if (pizzaBox40 > 0) {
      updatedPizzaBoxes.pizzaBox40 = {
        id: '4eb070b1-5736-420c-9103-5a4271ea1afc',
        name: 'Коробка для пиццы 40 см',
        price: 40,
        amount: pizzaBox40,
        productGroupId: '0f840178-dbb0-4cc0-8bad-a09620aa07d2',
      };
    }

    setPizzaBoxes(updatedPizzaBoxes);
  };

  const addToCart = (product) => {
    const uniqueKey = `${product.id}-${(product.modifiers || [])
      .map((mod) => mod.id)
      .join('-')}`;

    setCart((prevCart) => {
      const existingProduct = prevCart.find((item) => item.key === uniqueKey);

      if (existingProduct) {
        return prevCart.map((item) =>
          item.key === uniqueKey
            ? { ...item, amount: item.amount + 1 }
            : item
        );
      }

      const newProduct = {
        key: uniqueKey,
        productId: product.id,
        name: product.name,
        price: product.price,
        image: product.image,
        amount: 1,
        totalPrice: totalPrice,
        modifiers: (product.modifiers || []).map((mod) => ({
          productId: mod.id,
          name: mod.name,
          price: mod.price,
          amount: mod.amount,
          productGroupId: mod.productGroupId,
        })),
      };

      return [...prevCart, newProduct];
    });
  };

  const clearCart = () => {
    setCart([]);
  };

  const totalPrice = cart.reduce((sum, product) => {
    const productPrice = product.amount * product.price;
    const modifiersPrice = (product.modifiers || []).reduce(
      (modSum, mod) => modSum + mod.price * mod.amount,
      0
    );
    return sum + productPrice + modifiersPrice;
  }, 0) +
    (pizzaBoxes.pizzaBox30 ? pizzaBoxes.pizzaBox30.amount * pizzaBoxes.pizzaBox30.price : 0) +
    (pizzaBoxes.pizzaBox40 ? pizzaBoxes.pizzaBox40.amount * pizzaBoxes.pizzaBox40.price : 0);
  

  const increaseItemAmount = (key) => {
    setCart((prevCart) =>
      prevCart.map((item) =>
        item.key === key ? { ...item, amount: item.amount + 1 } : item
      )
    );
  };

  const decreaseItemAmount = (key) => {
    setCart((prevCart) =>
      prevCart
        .map((item) =>
          item.key === key && item.amount > 1
            ? { ...item, amount: item.amount - 1 }
            : item
        )
        .filter((item) => item.amount > 0)
    );
  };

  const removeItem = (key) => {
    setCart((prevCart) => prevCart.filter((item) => item.key !== key));
  };

  const getProductByName = (name) => {
    return menu.find((product) =>
      product.name.toLowerCase().includes(name.toLowerCase())
    );
  };

  return (
    <CartContext.Provider
      value={{
        cart,
        addToCart,
        clearCart,
        totalPrice,
        increaseItemAmount,
        decreaseItemAmount,
        removeItem,
        pizzaBoxes,
        updatePizzaBoxes,
        getProductByName,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};
