// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.marketing {
  margin: 116px auto 18px;
  padding: 0 18px;
  width: 100%;
  box-sizing: border-box;
}

.marketing__slide {
  width: auto;
  height: auto;
}

.marketing__slide_image {
  width: 100%;
  object-fit: cover;
  border-radius: 5px;
}

.swiper-button-next, .swiper-button-prev {
  color: rgb(122, 148, 90) !important;
}

@media screen and (max-width: 1200px) {
  .marketing {
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 108px;
    margin-bottom: 10px;
  }

  .marketing__slide {
    width: auto;
  }
}

@media screen and (max-width: 910px) {
  .marketing {
    margin-top: 101px;
  }
}

@media screen and (max-width: 700px) {
  .marketing {
    margin-top: 96px;
    padding-left: 5px;
    padding-right: 5px;
    margin-bottom: 5px;
  }
}

@media screen and (max-width: 520px) {
  .marketing {
    margin-top: 72px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Marketing.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,eAAe;EACf,WAAW;EACX,sBAAsB;AACxB;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,WAAW;EACX,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,mCAAmC;AACrC;;AAEA;EACE;IACE,kBAAkB;IAClB,mBAAmB;IACnB,iBAAiB;IACjB,mBAAmB;EACrB;;EAEA;IACE,WAAW;EACb;AACF;;AAEA;EACE;IACE,iBAAiB;EACnB;AACF;;AAEA;EACE;IACE,gBAAgB;IAChB,iBAAiB;IACjB,kBAAkB;IAClB,kBAAkB;EACpB;AACF;;AAEA;EACE;IACE,gBAAgB;EAClB;AACF","sourcesContent":[".marketing {\r\n  margin: 116px auto 18px;\r\n  padding: 0 18px;\r\n  width: 100%;\r\n  box-sizing: border-box;\r\n}\r\n\r\n.marketing__slide {\r\n  width: auto;\r\n  height: auto;\r\n}\r\n\r\n.marketing__slide_image {\r\n  width: 100%;\r\n  object-fit: cover;\r\n  border-radius: 5px;\r\n}\r\n\r\n.swiper-button-next, .swiper-button-prev {\r\n  color: rgb(122, 148, 90) !important;\r\n}\r\n\r\n@media screen and (max-width: 1200px) {\r\n  .marketing {\r\n    padding-left: 10px;\r\n    padding-right: 10px;\r\n    margin-top: 108px;\r\n    margin-bottom: 10px;\r\n  }\r\n\r\n  .marketing__slide {\r\n    width: auto;\r\n  }\r\n}\r\n\r\n@media screen and (max-width: 910px) {\r\n  .marketing {\r\n    margin-top: 101px;\r\n  }\r\n}\r\n\r\n@media screen and (max-width: 700px) {\r\n  .marketing {\r\n    margin-top: 96px;\r\n    padding-left: 5px;\r\n    padding-right: 5px;\r\n    margin-bottom: 5px;\r\n  }\r\n}\r\n\r\n@media screen and (max-width: 520px) {\r\n  .marketing {\r\n    margin-top: 72px;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
