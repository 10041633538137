import React, { useRef } from 'react';

const Navigation = React.forwardRef(({ groups, activeGroup, onSectionChange }, ref) => {
  const ulRef = useRef(null);

  const scrollLeft = () => {
    if (ulRef.current) {
      const scrollableWidth = ulRef.current.scrollWidth - ulRef.current.clientWidth;
      if (ulRef.current.scrollLeft > 0) {
        ulRef.current.scrollBy({ left: -100, behavior: 'smooth' });
      }
    }
  };
  
  const scrollRight = () => {
    if (ulRef.current) {
      const scrollableWidth = ulRef.current.scrollWidth - ulRef.current.clientWidth;
      if (ulRef.current.scrollLeft < scrollableWidth) {
        ulRef.current.scrollBy({ left: 100, behavior: 'smooth' });
      }
    }
  };

  const handleClick = (groupId, index) => {
    if (onSectionChange) onSectionChange(groupId);
    const liElement = ulRef.current.children[index];
    const ulElement = ulRef.current;

    const ulRect = ulElement.getBoundingClientRect();
    const liRect = liElement.getBoundingClientRect();

    if (liRect.right > ulRect.right) {
      ulElement.scrollBy({ left: liRect.right - ulRect.right + liElement.offsetWidth, behavior: 'smooth' });
    } else if (liRect.left < ulRect.left) {
      ulElement.scrollBy({ left: liRect.left - ulRect.left - liElement.offsetWidth, behavior: 'smooth' });
    }
  };

  const filteredGroups = groups && Array.isArray(groups)
    ? groups.filter(group =>
        group.parentGroup === null &&
        group.isGroupModifier === false &&
        !(group.name.toLowerCase().includes('доставка') ||
          group.name.toLowerCase().includes('невьянск'))
      )
    : [];

  return (
    <nav ref={ref}>
      <button className="scroll-button left" onClick={scrollLeft}>&lt;</button>
      <ul ref={ulRef}>
        {filteredGroups.map((group, index) => (
          <li
            key={group.id}
            data-id={group.id}
            onClick={() => handleClick(group.id, index)}
            className={group.id === activeGroup ? 'active' : ''}
          >
            <a href={`#${group.id}`}>{group.name}</a>
          </li>
        ))}
      </ul>
      <button className="scroll-button right" onClick={scrollRight}>&gt;</button>
    </nav>
  );
});

export default Navigation;
