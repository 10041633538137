import React, { useState, useEffect } from 'react';
import Header from './Header';
import Marketing from './Marketing';
import Products from './Products';
import Footer from './Footer';
import CurrentUserContext from '../contexts/CurrentUserContext';
import { CartProvider } from '../contexts/cardContext';
import { BASE_URL } from '../utils/config';

function App() {
  const [user, setUser] = useState(null);
  const [menu, setMenu] = useState([]);

  useEffect(() => {
    fetchMenuData();
  }, []);

  const fetchMenuData = async () => {
    try {
      const response = await fetch(`${BASE_URL}/nomenclature`, {
        method: 'GET',
      });
      const data = await response.json();
      setMenu(data);
    } catch (error) {
      console.error('Error fetching menu data:', error);
    }
  };

  return (
    <CurrentUserContext.Provider value={{ user, setUser }}>
      <CartProvider menu={menu.products || []}>
        <div className="app">
          <Header />
          <Marketing />
          <Products groups={menu.groups} products={menu.products} />
          <Footer />  
        </div>
      </CartProvider>
    </CurrentUserContext.Provider>
  );
}

export default App;
