import React, { useRef, useEffect } from 'react';
import { Box } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import delivery from '../images/delivery.jpg';
import birthday from '../images/birthday.jpg';
import happyhour from '../images/happy_hour.jpg';
import vacancy from '../images/vacancy.jpg';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

function Marketing() {
  const swiperRef = useRef(null);

  const handleWheel = (event) => {
    if (swiperRef.current) {
      const swiper = swiperRef.current.swiper;
      event.preventDefault();
      event.stopPropagation();
      if (event.deltaY > 0) {
        swiper.slideNext();
      } else {
        swiper.slidePrev();
      }
    }
  };

  const slides = [
    {
      type: "image",
      src: delivery,
    },
    {
      type: "image",
      src: happyhour,
    },
    {
      type: "image",
      src: birthday,
    },
    {
      type: "image",
      src: vacancy,
    },
  ];

  useEffect(() => {
    const swiperElement = swiperRef.current;
    if (swiperElement) {
      swiperElement.addEventListener('wheel', handleWheel, { passive: false });
      return () => {
        swiperElement.removeEventListener('wheel', handleWheel);
      };
    }
  }, []);

  return (
    <Box
      className="marketing"
    >
      <Swiper
        modules={[Navigation, Pagination]}
        navigation
        pagination={{ clickable: true }}
        spaceBetween={20}
        slidesPerView="auto"
        ref={swiperRef}
        style={{ width: '100%' }}
      >
        {slides.map((slide, index) => (
          <SwiperSlide key={index} className="marketing__slide">
            {slide.link ? (
              <a
                href={slide.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className='marketing__slide_image'
                  src={slide.src}
                  alt={`Логотип ${index + 1}`}
                />
              </a>
            ) : (
              <img
                className='marketing__slide_image'
                src={slide.src}
                alt={`Логотип ${index + 1}`}
              />
            )}
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  );
}

export default Marketing;
